<template>
  <v-container>
    <v-row>
    <v-col>
      <v-btn tile class="primary" @click="createForm">
        <v-icon>mdi-plus</v-icon>
        Add Form
      </v-btn>
    </v-col>
    </v-row>
    <v-row v-if="licenses.filter(value => value.productNumber === '48102') < 1">
      <v-col>
        <v-alert
            dense
            border="left"
            type="warning"
        >
          For Forms a additional license is required. The only Forms that will work correctly without license are "Remark" and "Remark (required)"
        </v-alert>
      </v-col>

    </v-row>
    <v-row>
      <v-col class="col-lg-6 col-sm-12 col-sm-12 col-12" v-for="(form) in appFormList" :key="form.id">
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon large>mdi-clipboard-list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ form.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn color="warning" icon @click="editForm(form)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="copyForm(form)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="createField(form)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="error" icon @click="deleteForm(form)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
              </v-list-item>
              <v-divider class="blue-grey" />

              <v-list-item v-if="form.formFields.length === 0" class="pt-2">
                <v-list-item-content>
                  <v-list-item-title class="text-center align-center text--secondary">
                    No fields defined!
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list class="pb-0" v-else>
                <template v-for="(field, index) in form.formFields">
                  <v-list-item :key="field.id" dense class="tile">
                    <v-list-item-icon>
                      <v-icon v-if="field.type === 'number'" large>mdi-numeric</v-icon>
                      <v-icon v-if="field.type === 'text'" large>mdi-form-textbox</v-icon>
                      <v-icon v-if="field.type === 'bool'" large>mdi-ab-testing</v-icon>
                      <v-icon v-if="field.type === 'date'" large>mdi-calendar-today</v-icon>
                      <v-icon v-if="field.type === 'time'" large>mdi-clock-time-three-outline</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ field.label }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <v-btn
                          color="secondary"
                          icon
                          @click="moveInList(form, form.formFields, index, index - 1)"
                          :disabled="index === 0"
                      >
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                          color="secondary"
                          icon
                          @click="moveInList(form, form.formFields, index, index + 1)"
                          :disabled="index === (form.formFields.length - 1)"
                      >
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                      <v-btn color="warning" icon @click="editField(form, field)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn color="error" icon @click="deleteListItem(form, form.formFields, index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              <v-divider
                  v-if="index < form.formFields.length - 1"
                  :key="index"
              />
            </template>
          </v-list>
        </v-list>
      </v-col>
    </v-row>

    <!-- region Form Dialog -->
    <v-dialog
        persistent
        v-model="dialogForm"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT/COPY APP FORM</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="form.name"
                  label="Name"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->
    <!-- region Field Dialog -->
    <v-dialog
        persistent
        v-model="dialogField"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">ADD/EDIT APP FORM FIELD</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              <v-text-field
                  prepend-icon="mdi-key"
                  v-model="field.key"
                  label="Key"
                  :rules="[
                      v => !!v || 'Key is required',
                      v => /^[a-zA-Z0-9]+$/.test(v) || 'Can only contain a-z, A-Z and 0-9',
                  ]"
              />
            </v-col>
            <v-col>
              <v-select
                  v-model="field.type"
                  label="Field type"
                  item-value="type"
                  :items="fieldTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                  prepend-icon="mdi-label"
                  v-model="field.label"
                  label="Label"
              />
            </v-col>
            <v-col>
              <v-text-field
                  prepend-icon="mdi-label-variant"
                  v-model="field.suffix"
                  label="Suffix"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="field.required"
                label="Required"
                />
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveField">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Delete App Layout Dialog -->
    <v-dialog
        persistent
        v-model="dialogDeleteForm"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">DELETE APP LAYOUT "{{form.name}}"</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              Should the "{{form.name}}" be deleted?
              <br>
              <b class="error--text">IMPORTANT: This cannot be undone!</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="error" @click="confirmDeletionOfForm">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->
    <!-- region Delete Dialog -->
    <v-dialog
        persistent
        v-model="dialogDeleteList"
        max-width="800"
        scrollable
        @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar>
            <span class="headline">DELETE "{{deleteIdentifier}}" FROM LIST</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4 pr-10 pl-10 pb-4">
          <v-row dense>
            <v-col>
              Should the "{{deleteIdentifier}}" be deleted?
              <br>
              <b class="error--text">IMPORTANT: This cannot be undone!</b>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="error" @click="confirmDeletion">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

  </v-container>
</template>

<script>
import MDI_ICONS from '@/common/mdi_icons';
import FIELD_TYPES from "@/common/field_types";
import {mapGetters} from "vuex";
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";

export default {
  name: "AppForm",
  data: function () {
    return {
      icons: MDI_ICONS,
      fieldTypes: FIELD_TYPES,
      dialogForm: false,
      dialogField: false,
      dialogDeleteForm: false,
      dialogDeleteList: false,
      form: {},
      field: {},
      deleteIndex: -1,
      deleteList: [],
      licenses: []
    }
  },
  mounted() {
    this.getLicenses()
    this.$store.dispatch("getAppFormsList")
  },
  methods: {
    getLicenses() {
      axios.get(`${API_URL}licenses`, {headers: JwtService.getHeader()})
          .then(value => this.licenses = value.data)
          .catch(reason => console.log(reason))
    },
    /**
     * Opens the dialog to create a new Form
     */
    createForm() {
      this.dialogForm = true
    },
    /**
     * Opens the dialog to edit a form
     * @param form Form to edit
     */
    editForm(form) {
      this.form = form
      this.dialogForm = true
    },
    copyForm(form) {
      delete form.id
      this.form = Object.assign({}, form)
      this.dialogForm = true
    },
    /**
     * Function to save the entered or modified Form
     */
    saveForm() {
      let form = Object.assign({}, this.form)

      if ('id' in this.form) {
        // Editing
        form.formFields = JSON.stringify(form.formFields)
      } else {
        // Creating
        if ('formFields' in form) {
          form.formFields = JSON.stringify(form.formFields)
        } else {
          form.formFields = '[]'
        }
      }

      this.writeForm(form)
      this.closeDialog()
    },

    /**
     * Function that deletes the terminal permanently
     */
    confirmDeletionOfForm() {
      axios.delete(`${API_URL}appForm/${this.deleteIndex}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getAppFormsList")
          })
      this.closeDialog()
    },

    writeForm(form) {
      axios.post(`${API_URL}appForm`, form, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getAppFormsList")
          })
    },

    createField(form) {
      this.form = form
      this.dialogField = true
    },

    editField(form, field) {
      this.form = form
      this.field = field
      this.dialogField = true
    },

    moveInList(formDef, list, from, to) {
      let move = list.splice(from, 1)[0]
      list.splice(to, 0, move)

      let form = Object.assign({}, formDef)
      form.formFields = JSON.stringify(form.formFields)
      this.writeForm(form)
    },

    saveField() {
      let form = Object.assign({}, this.form)
      if ('id' in this.field) {
        // Editing
        form.formFields = JSON.stringify(form.formFields)
      } else {
        // Creating
        this.field.id = this.$uuid.v4()
        form.formFields.push(this.field)
        form.formFields = JSON.stringify(form.formFields)
      }
      this.writeForm(form)
      this.closeDialog()
    },

    deleteListItem(form, list, index) {
      this.form = form
      this.deleteList = list
      this.deleteIndex = index
      this.dialogDeleteList = true
    },

    deleteForm(form) {
      this.deleteIndex = form.id
      this.form = form
      this.dialogDeleteForm = true
    },

    confirmDeletion(){
      this.deleteList.splice(this.deleteIndex, 1)
      let form = Object.assign({}, this.form)
      form.formFields = JSON.stringify(form.formFields)
      this.writeForm(form)
      this.closeDialog()
    },

    /**
     * Function to handle closing the dialogs
     */
    closeDialog(){
      if(this.dialogForm) this.dialogForm = !this.dialogForm
      if(this.dialogField) this.dialogField = !this.dialogField
      if(this.dialogDeleteForm) this.dialogDeleteForm = !this.dialogDeleteForm
      if(this.dialogDeleteList) this.dialogDeleteList = !this.dialogDeleteList

      this.form = {}
      this.field = {}
      this.deleteIndex = -1
      this.deleteList = []
      this.$store.dispatch("getAppFormsList")
    },
  },
  computed: {
    ...mapGetters([
      "appFormList"
    ]),
    deleteIdentifier: function () {
      if (this.deleteList.length > 0 && this.deleteIndex !== -1) {
        if ('label' in this.deleteList[this.deleteIndex]) return this.deleteList[this.deleteIndex].label
      }
      return ''

    }
  }
}

</script>

<style scoped>
.tile:hover {
  background-color: lightgray;
}
</style>
