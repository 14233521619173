<template>
  <v-container>
    <v-list elevation="4" class="mt-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h4">Groups</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn tile color="primary" @click="createItem">
            <v-icon>mdi-plus</v-icon>
            Add group
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="userGroupList.isEmpty">
        <v-list-item-content>
          <v-list-item-title class="text-center">There are no groups defined</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group>
        <template v-for="(group, index) in userGroupList">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title>{{ group.description }} ({{group.id}})</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn icon @click="editItem(group)">
                  <v-icon color="warning">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(group)">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-divider
              v-if="index < userGroupList.length - 1"
              :key="`_${index}`"
          />
        </template>

      </v-list-item-group>
    </v-list>

    <v-dialog
        v-model="dialog"
        max-width="900"
        scrollable
        transition="dialog-bottom-transition"
        persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">{{ getFormDialogTitle }} - {{ editedItem.description }}</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.id"
                    type="number"
                    :readonly="editing"
                    prepend-icon="mdi-key"
                    label="Group Id"
                >

                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.description"
                    prepend-icon="mdi-label"
                    label="Group name"
                >

                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="usersForGroup"
                    :items="userList"
                    label="Users"
                    prepend-icon="mdi-account-multiple"
                    append-outer-icon="mdi-select-multiple"
                    @click:append-outer="usersForGroup = userList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.firstname} ${item.lastname} (${item.number})`"
                />
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveItem">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogDelete"
        max-width="900"
        transition="dialog-bottom-transition"
        persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer/>
          <v-toolbar-title class="headline">Delete group "{{ editedItem.description }}"</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-layout align-center justify-center>
            <v-flex xs12 sm12 md12>
              <h1 class="text-h5 d-flex justify-center">Schould group "{{ editedItem.description }}" be deleted?</h1>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import {mapGetters} from "vuex";

export default {
  name: "Groups",
  data: function () {
    return {
      //region Local data
      search: '',
      dialog: false,
      dialogDelete: false,
      editing: false,
      editedItem: {},
      //endregion

      //region API data
      usersForGroup: [],
      //endregion
    }
  },
  mounted() {
    this.$store.dispatch("getUserList")
    this.$store.dispatch("getUserGroupList")
  },
  methods: {
    editItem(item) {
      this.editing = true;
      this.editedItem = Object.assign({}, item)
      this.getUsersForGroup(this.editedItem.id)
      this.dialog = true
    },
    createItem() {
      this.editing = false;
      this.dialog = true
    },
    saveItem() {
      if (this.editing) {
        axios.put(`${API_URL}group`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getUserList")
              this.$store.dispatch("getUserGroupList")
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        axios.post(`${API_URL}group`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getUserList")
              this.$store.dispatch("getUserGroupList")
            })
            .catch(error => {
              console.log(error)
            })
      }

      axios.put(`${API_URL}group/${this.editedItem.id}/users`, this.usersForGroup, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })


      this.closeDialog()
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deletionConfirmed() {
      axios.delete(`${API_URL}group/${this.editedItem.id}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getUserList")
            this.$store.dispatch("getUserGroupList")
          })
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    closeDialog() {
      if (this.dialog) this.dialog = false;
      if (this.dialogDelete) this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {}
        this.usersForGroup = []
      });
    },
    getUsersForGroup(groupNumber) {
      axios.get(`${API_URL}group/${groupNumber}/users`, {headers: JwtService.getHeader()})
          .then(response => {
            this.usersForGroup = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
  },
  computed: {
    ...mapGetters([
      "userList",
      "userGroupList",
    ]),
    getFormDialogTitle() {
      return (this.editing) ? "Edit group" : "Create new group"
    }
  }
}
</script>

<style scoped>

</style>
