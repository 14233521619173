<template>
  <router-view />
</template>

<style>
input[type="color"] {
  padding: 1px 2px !important;
}
</style>

<script>

export default {
};
</script>
