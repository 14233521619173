<template>
  <v-container>
    <v-row>
      <v-col class="mt-10">
        <v-slider label="Size of QR Code"
                  min="1"
                  max="15"
                  thumb-label="always"
                  v-model="qrSize"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="text-h2">Connection QR Code</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>
              <v-text-field
                  v-model="connection.serverAddress"
                  label="Server address"
                  prepend-icon="mdi-server"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-content>
              <v-text-field
                  v-model="connection.username"
                  label="Username"
                  prepend-icon="mdi-account"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-content>
              <v-text-field
                  v-model="connection.password"
                  label="Password"
                  prepend-icon="mdi-key"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col align="center" justify="center">
        <QRCanvas :options="connectionQr"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="text-h2">Booking QR Code</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.project"
                  prepend-icon="mdi-chart-gantt"
                  label="Project"
                  append-outer-icon="mdi-delete"
                  :items="projectList"
                  :item-text="item => `${item.name} (${item.number})`"
                  item-value="number"
                  @click:append-outer="bookingData.project = ''"
                  @change="getProjectStates()"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="projectStates.length > 0">
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.projectState"
                  :items="projectStates"
                  prepend-icon="mdi-state-machine"
                  label="Project state"
                  item-value="number"
                  :item-text="item => `${item.description} (${states.find(value => value.value === item.state).label})`"
                  append-outer-icon="mdi-delete"
                  @click:append-outer="bookingData.projectState = ''"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.workplace"
                  prepend-icon="mdi-office-building-marker"
                  label="Workplace"
                  append-outer-icon="mdi-delete"
                  :items="workplaceList"
                  :item-text="item => `${item.name} (${item.number})`"
                  item-value="number"
                  @click:append-outer="bookingData.workplace = ''"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.costCenter"
                  prepend-icon="mdi-tag"
                  label="Cost center"
                  append-outer-icon="mdi-delete"
                  :items="costCenterList"
                  :item-text="item => `${item.name} (${item.number})`"
                  item-value="number"
                  @click:append-outer="bookingData.costCenter = ''"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.employee"
                  prepend-icon="mdi-account"
                  label="Employee"
                  append-outer-icon="mdi-delete"
                  :items="userList"
                  :item-text="item => `${item.firstname} ${item.lastname} (${item.number})`"
                  item-value="number"
                  @click:append-outer="bookingData.employee = ''"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  v-model="bookingData.resource"
                  prepend-icon="mdi-toolbox"
                  label="Resource"
                  append-outer-icon="mdi-delete"
                  :items="resourceList"
                  :item-text="item => `${item.name} (${item.number})`"
                  item-value="number"
                  @click:append-outer="bookingData.resource = ''"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="bookingData.latitude"
                      label="Latitude"
                      type="number"
                      prepend-icon="mdi-latitude"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="bookingData.longitude"
                      label="Longitude"
                      type="number"
                      prepend-icon="mdi-longitude"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="bookingData.radius"
                      label="Radius"
                      type="number"
                      prepend-icon="mdi-map-marker-radius"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn color="primary" @click="addSignature()">
              Sign the QR code
            </v-btn>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col align="center" justify="center">
        <QRCanvas :options="bookingQr"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {QRCanvas} from 'qrcanvas-vue';
import {mapGetters} from "vuex";
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";

export default {
  name: "QrGenerator",
  components: {
    QRCanvas,
  },
  data: function () {
    return {
      qrSize:5,
      connection: {
        serverAddress: `https://${window.location.host}`,
        username: "",
        password: "",
      },
      signBookingQr: false,
      bookingData: {
        project: "",
        projectState: "",
        workplace: "",
        costCenter: "",
        resource: "",
        employee: "",
        latitude: 0.,
        longitude: 0.,
        radius: 0.,
        signature: "",
      },
      states: [
        {value: 0, label: "Free"},
        {value: 1, label: "Locked"},
        {value: 2, label: "Completed"},
      ],
      projectStates: [],
    }
  },
  methods: {
    getProjectStates() {
      if (this.bookingData.project === "") return
      axios.get(`${API_URL}project_states/${this.bookingData.project}`, {headers: JwtService.getHeader()})
          .then(response => {
            this.projectStates = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    addSignature() {
      axios.post(`${API_URL}sign_qr_code`, this.bookingData,{headers: JwtService.getHeader()})
          .then(response => {
            this.bookingData.signature = response.data.signature
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  mounted() {
    this.$store.dispatch("getUserList")
    this.$store.dispatch("getProjectList")
    this.$store.dispatch("getWorkplaceList")
    this.$store.dispatch("getCostCenterList")
    this.$store.dispatch("getResourcesList")
  },
  computed: {
    ...mapGetters([
      "userList",
      "projectList",
      "workplaceList",
      "costCenterList",
      "resourceList",
    ]),
    connectionQr: function () {
      return {
        cellSize: this.qrSize,
        data: JSON.stringify(this.connection),
      }
    },
    bookingQr: function () {
      return {
        cellSize: this.qrSize,
        data: JSON.stringify(this.bookingData),
      }
    },
    qrData: function () {
      return JSON.stringify(this.bookingData)
    }
  }
}
</script>

<style scoped>

</style>
