<template>
  <v-container fluid>
    <h1 class="text-h2 pb-10">Device Summary</h1>
    <v-row class="pb-10">
      <v-col cols="3" class="col-sm-12 col-md-6 col-lg-3">
        <v-card elevation="4">
          <v-card-title class="text-h4">
            <v-icon size="64">mdi-rocket-launch</v-icon>
            <span class="ml-4">Version Info</span>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Installed</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2" v-bind:class="{'error': summary.general.updateState, 'success': !summary.general.updateState}">{{ summary.general.currentVersion }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Latest Release</v-list-item-title>
                  <v-list-item-subtitle v-if="!summary.general.latestServerRelease">No release information received from server</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="summary.general.latestServerRelease">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip class="ma-2" v-bind="attrs" v-on="on">{{ summary.general.latestServerRelease }}</v-chip>
                      </template>
                      <span>{{ summary.general.latestServerNotes }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Latest App Release</v-list-item-title>
                  <v-list-item-subtitle v-if="!summary.general.latestServerRelease">No release information received from server</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="summary.general.latestServerRelease">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip class="ma-2" v-bind="attrs" v-on="on">{{ summary.general.latestAppRelease }}</v-chip>
                      </template>
                      <span>{{ summary.general.latestAppNotes }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3" class="col-sm-12 col-md-6 col-lg-3">
        <v-card elevation="4">
          <v-card-title class="text-h4">
            <v-icon size="64">mdi-sigma</v-icon>
            <span class="ml-4">General</span>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-list v-if="summary.general">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Number of Users</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2">{{ summary.general.numberOfUsers }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Number of bookings</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2">{{ summary.general.totalBookings }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3" class="col-sm-12 col-md-6 col-lg-3" v-for="stat in summary.deviceStats" :key="stat.os">
        <v-card elevation="4">
          <v-card-title class="text-h4" v-if="stat.os === 'ios'">
            <v-icon size="64" color="grey">mdi-apple</v-icon>
            <span class="ml-4">iOS</span>
          </v-card-title>
          <v-card-title class="text-h4 text-center" v-if="stat.os === 'android'">
            <v-icon size="64" color="green">mdi-android</v-icon>
            <span class="ml-4">Android</span>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Number of Devices</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2">{{ stat.numberOfDevices }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>OS Versions</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2" v-for="(value, key) in stat.osVersions" :key="key">{{ value }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>App Versions</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip class="ma-2" v-for="(value, key) in stat.appVersions" :key="key">{{ value }}</v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h1 class="text-h2 pb-10">Bookings</h1>
    <v-data-table
        :headers="headers"
        :items="bookings"
        :search="search"
        class="elevation-2"
        :items-per-page="50"
        dense
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>
            <v-icon>mdi-chart-gantt</v-icon>
            Bookings
          </v-toolbar-title>
          <v-spacer/>
          <v-text-field
              v-model="search"
              placeholder="Search"
              append-icon="mdi-magnify"
          />
          <v-spacer/>
          <v-icon
              class="mr-2"
              @click="getBookings()"
          >
            mdi-refresh
          </v-icon>
        </v-toolbar>
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | formatBookingDateTime(item.timeZoneOffset) }}
      </template>
      <template v-slot:item.syncTimestamp="{ item }">
        {{ item.syncTimestamp | formatSyncDateTime }}
      </template>
      <template v-slot:item.timeZoneOffset="{ item }">
        <span v-if="item.timeZoneOffset">{{ item.timeZoneOffset | timeZoneFormat }}</span>
      </template>
      <template v-slot:item.project="{ item }">
        <span v-if="item.project">{{ item.projectName }} ({{ item.project }})</span>
      </template>
      <template v-slot:item.projectState="{ item }">
        <span v-if="item.projectState">{{ item.projectStateName }} ({{ item.projectState }})</span>
      </template>
      <template v-slot:item.workplace="{ item }">
        <span v-if="item.workplace">{{ item.workplaceName }} ({{ item.workplace }})</span>
      </template>
      <template v-slot:item.costCenter="{ item }">
        <span v-if="item.costCenter">{{ item.costCenterName }} ({{ item.costCenter }})</span>
      </template>
      <template v-slot:item.syncState="{ item }">
        <v-tooltip left v-if="item.syncState !== 'OK'">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.syncState }}</span>
          </template>
          <span>{{ item.syncErrorMessage }}</span>
        </v-tooltip>
        <span v-if="item.syncState === 'OK'">{{ item.syncState }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import {DateTime} from "luxon";

export default {
  name: "Dashboard",
  data: function () {
    return {
      search: '',
      bookings: [],
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Date', value: 'date', class: 'text-no-wrap'},
        {text: 'TZ Offset', value: 'timeZoneOffset'},
        {text: 'Created by', value: 'createdBy'},
        {text: 'Project', value: 'project'},
        {text: 'Project State', value: 'projectState'},
        {text: 'Cost Center', value: 'costCenter'},
        {text: 'Workplace', value: 'workplace'},
        {text: 'Employee', value: 'employee'},
        {text: 'Time Account', value: 'timeAccount'},
        {text: 'Comment', value: 'comment'},
        {text: 'Sync Date (UTC)', value: 'syncTimestamp', class: 'text-no-wrap'},
        {text: 'Sync Retries ', value: 'syncRetries'},
        {text: 'Sync State', value: 'syncState'},
      ],
      timer: '',
      summary: {},
    }
  },
  mounted() {
    this.getBookings()
    this.getSummary()
    this.timer = setInterval(this.getBookings, 10 * 1000);
    this.serverTimeZone = this.$store.getters.atcTimeZone
  },
  methods: {
    getBookings() {
      axios.get(`${API_URL}bookings`, {headers: JwtService.getHeader()})
          .then(response => {
            this.bookings = response.data
            this.bookings.forEach(value => {
              value.action = JSON.parse(value.action)
              value.filledForms = JSON.parse(value.filledForms)
              value.forms = JSON.parse(value.forms)
              value.selectedItems = JSON.parse(value.selectedItems)
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    getSummary(){
      axios.get(`${API_URL}device/summary`, {headers: JwtService.getHeader()})
          .then(response => {
            this.summary = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
  },
  filters: {
    formatBookingDateTime: function (value, timeZoneOffset) {
      if (timeZoneOffset === undefined) timeZoneOffset = 0
      return DateTime.fromISO(value).toUTC().plus({seconds: timeZoneOffset}).toFormat("dd.MM.yyyy HH:mm")
    },
    formatSyncDateTime: function (value) {
      return DateTime.fromISO(value).toUTC().toFormat("dd.MM.yyyy HH:mm")
    },
    timeZoneFormat: function (value) {
      let val = value / (60 * 60)
      return (val >= 0) ? `UTC +${val}` : `UTC ${val}`
    },
  }
}
</script>

<style scoped>

</style>
