<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-h2">Global Settings</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-12 col-md-12 col-lg-6">
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-title class="text-h4">Licenses</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item>
            <v-text-field
                v-model="activationKey"
                label="Activation key"
                append-outer-icon="mdi-floppy"
                placeholder="00000000-0000-0000-0000-000000000000"
                @click:append-outer="activateLicense()"
            />
          </v-list-item>
          <v-divider/>
          <template v-for="(license, index) in licenses">
            <v-list-item :key="license.signature">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ license.productName }}</v-list-item-title>
                <v-list-item-subtitle class="ml-3 mt-2">Licensee: {{ license.licensee }}</v-list-item-subtitle>
                <v-list-item-subtitle class="ml-3">Licensed users: {{ license.licensedUsers }}</v-list-item-subtitle>
                <v-list-item-subtitle class="ml-3">Expires at: {{ license.expiresAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < licenses.length - 1" :key="index"/>
          </template>
        </v-list>
      </v-col>

      <v-col class="col-sm-12 col-md-12 col-lg-6">
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-title class="text-h4">Rights Management</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item
              v-for="entry in rightsManagement"
              :key="entry.setting"
          >
            <v-list-item-content>
              <v-list-item-title>{{ entry.label }}</v-list-item-title>
              <v-list-item-subtitle>{{ entry.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                  :input-value="entry.value"
                  @click="toggleBooleanSetting(entry.setting)"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="text-h2">ATOSS Time Control Settings</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-12 col-md-12 col-lg-6">
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-title class="text-h4">ATOSS Time Control server</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                  label="ATOSS Time Control sever address"
                  prepend-icon="mdi-server"
                  hint="URL of the ATOSS Time Control webservice"
                  persistent-hint
                  v-model="atcUrl.value"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                  label="ATOSS Time Control username"
                  prepend-icon="mdi-account"
                  hint="Username to access ATOSS Time Control webservice"
                  persistent-hint
                  v-model="atcUsername.value"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                  label="ATOSS Time Control password"
                  prepend-icon="mdi-lock"
                  hint="Password to access ATOSS Time Control webservice"
                  persistent-hint
                  :append-icon="showAtcPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showAtcPassword ? 'text' : 'password'"
                  @click:append="showAtcPassword = !showAtcPassword"
                  v-model="atcPassword.value"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-autocomplete
                  label="ATOSS Time Control server time zone"
                  prepend-icon="mdi-map-clock"
                  hint="Timezone of the ATOSS Time Control server needed for correct booking time"
                  persistent-hint
                  :items="timezones"
                  :item-text="item => `${item.timeZoneName.replaceAll('_', ' ')} (${item.timeZoneOffset})`"
                  item-value="timeZoneName"
                  v-model="atcTimeZone.value"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Synchronization job enabled?</v-list-item-title>
              <v-list-item-subtitle>Enables or disables the synchronization job (Service restart required)
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                  v-model="atcJobEnabled.value"
              />
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                  label="Synchronization job interval"
                  prepend-icon="mdi-timer-outline"
                  type="number"
                  placeholder="30000"
                  hint="Interval in which the synchronization job runs (Service restart required)"
                  persistent-hint
                  suffix="ms"
                  v-model="atcJobInterval.value"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-btn
                      color="primary"
                      block
                      @click="saveAtcSettings"
                  >Save
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                      color="error"
                      block
                      @click="restartService"
                  >Restart Service
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col class="col-sm-12 col-md-12 col-lg-6">
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-title class="text-h4">ATOSS Table Synchronisation</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item
              v-for="entry in synchronisationMode"
              :key="entry.setting"
              dense
          >
            <v-list-item-content>
              <v-autocomplete
                  :items="synchronisationModeItems"
                  item-text="mode"
                  item-value="value"
                  :label="entry.label"
                  :hint="entry.description"
                  persistent-hint
                  v-model="entry.value"
                  auto-select-first
                  @change="saveSetting(entry)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";

export default {
  name: "Settings",
  data: function () {
    return {
      timezones: [{timeZoneName: "Africa/Abidjan", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Accra", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Addis_Ababa", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Algiers", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Asmara", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Bamako", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Bangui", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Banjul", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Bissau", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Blantyre", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Brazzaville", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Bujumbura", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Cairo", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Casablanca", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Ceuta", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Conakry", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Dakar", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Dar_es_Salaam", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Djibouti", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Douala", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/El_Aaiun", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Freetown", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Gaborone", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Harare", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Johannesburg", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Juba", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Kampala", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Khartoum", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Kigali", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Kinshasa", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Lagos", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Libreville", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Lome", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Luanda", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Lubumbashi", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Lusaka", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Malabo", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Maputo", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Maseru", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Mbabane", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Mogadishu", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Monrovia", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Nairobi", timeZoneOffset: "+03:00"},
        {timeZoneName: "Africa/Ndjamena", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Niamey", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Nouakchott", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Ouagadougou", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Porto-Novo", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Sao_Tome", timeZoneOffset: "+00:00"},
        {timeZoneName: "Africa/Tripoli", timeZoneOffset: "+02:00"},
        {timeZoneName: "Africa/Tunis", timeZoneOffset: "+01:00"},
        {timeZoneName: "Africa/Windhoek", timeZoneOffset: "+02:00"},
        {timeZoneName: "America/Adak", timeZoneOffset: "−10:00"},
        {timeZoneName: "America/Anchorage", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/Anguilla", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Antigua", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Araguaina", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Buenos_Aires", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Catamarca", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Cordoba", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Jujuy", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/La_Rioja", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Mendoza", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Rio_Gallegos", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Salta", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/San_Juan", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/San_Luis", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Tucuman", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Argentina/Ushuaia", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Aruba", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Asuncion", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Atikokan", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Bahia", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Bahia_Banderas", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Barbados", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Belem", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Belize", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Blanc-Sablon", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Boa_Vista", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Bogota", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Boise", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Cambridge_Bay", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Campo_Grande", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Cancun", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Caracas", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Cayenne", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Cayman", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Chicago", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Chihuahua", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Costa_Rica", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Creston", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Cuiaba", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Curacao", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Danmarkshavn", timeZoneOffset: "+00:00"},
        {timeZoneName: "America/Dawson", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Dawson_Creek", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Denver", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Detroit", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Dominica", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Edmonton", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Eirunepe", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/El_Salvador", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Fort_Nelson", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Fortaleza", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Glace_Bay", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Goose_Bay", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Grand_Turk", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Grenada", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Guadeloupe", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Guatemala", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Guayaquil", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Guyana", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Halifax", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Havana", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Hermosillo", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Indiana/Indianapolis", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Indiana/Knox", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Indiana/Marengo", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Indiana/Petersburg", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Indiana/Tell_City", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Indiana/Vevay", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Indiana/Vincennes", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Indiana/Winamac", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Inuvik", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Iqaluit", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Jamaica", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Juneau", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/Kentucky/Louisville", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Kentucky/Monticello", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Kralendijk", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/La_Paz", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Lima", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Los_Angeles", timeZoneOffset: "−08:00"},
        {timeZoneName: "America/Lower_Princes", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Maceio", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Managua", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Manaus", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Marigot", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Martinique", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Matamoros", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Mazatlan", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Menominee", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Merida", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Metlakatla", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/Mexico_City", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Miquelon", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Moncton", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Monterrey", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Montevideo", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Montserrat", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Nassau", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/New_York", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Nipigon", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Nome", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/Noronha", timeZoneOffset: "−02:00"},
        {timeZoneName: "America/North_Dakota/Beulah", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/North_Dakota/Center", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/North_Dakota/New_Salem", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Nuuk", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Ojinaga", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Panama", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Pangnirtung", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Paramaribo", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Phoenix", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Port-au-Prince", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Port_of_Spain", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Porto_Velho", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Puerto_Rico", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Punta_Arenas", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Rainy_River", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Rankin_Inlet", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Recife", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Regina", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Resolute", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Rio_Branco", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Santarem", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Santiago", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Santo_Domingo", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Sao_Paulo", timeZoneOffset: "−03:00"},
        {timeZoneName: "America/Scoresbysund", timeZoneOffset: "−01:00"},
        {timeZoneName: "America/Sitka", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/St_Barthelemy", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/St_Johns", timeZoneOffset: "−03:30"},
        {timeZoneName: "America/St_Kitts", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/St_Lucia", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/St_Thomas", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/St_Vincent", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Swift_Current", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Tegucigalpa", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Thule", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Thunder_Bay", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Tijuana", timeZoneOffset: "−08:00"},
        {timeZoneName: "America/Toronto", timeZoneOffset: "−05:00"},
        {timeZoneName: "America/Tortola", timeZoneOffset: "−04:00"},
        {timeZoneName: "America/Vancouver", timeZoneOffset: "−08:00"},
        {timeZoneName: "America/Whitehorse", timeZoneOffset: "−07:00"},
        {timeZoneName: "America/Winnipeg", timeZoneOffset: "−06:00"},
        {timeZoneName: "America/Yakutat", timeZoneOffset: "−09:00"},
        {timeZoneName: "America/Yellowknife", timeZoneOffset: "−07:00"},
        {timeZoneName: "Antarctica/Casey", timeZoneOffset: "+11:00"},
        {timeZoneName: "Antarctica/Davis", timeZoneOffset: "+07:00"},
        {timeZoneName: "Antarctica/DumontDUrville", timeZoneOffset: "+10:00"},
        {timeZoneName: "Antarctica/Macquarie", timeZoneOffset: "+10:00"},
        {timeZoneName: "Antarctica/Mawson", timeZoneOffset: "+05:00"},
        {timeZoneName: "Antarctica/McMurdo", timeZoneOffset: "+12:00"},
        {timeZoneName: "Antarctica/Palmer", timeZoneOffset: "−03:00"},
        {timeZoneName: "Antarctica/Rothera", timeZoneOffset: "−03:00"},
        {timeZoneName: "Antarctica/Syowa", timeZoneOffset: "+03:00"},
        {timeZoneName: "Antarctica/Troll", timeZoneOffset: "+00:00"},
        {timeZoneName: "Antarctica/Vostok", timeZoneOffset: "+06:00"},
        {timeZoneName: "Arctic/Longyearbyen", timeZoneOffset: "+01:00"},
        {timeZoneName: "Asia/Aden", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Almaty", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Amman", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Anadyr", timeZoneOffset: "+12:00"},
        {timeZoneName: "Asia/Aqtau", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Aqtobe", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Ashgabat", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Atyrau", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Baghdad", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Bahrain", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Baku", timeZoneOffset: "+04:00"},
        {timeZoneName: "Asia/Bangkok", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Barnaul", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Beirut", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Bishkek", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Brunei", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Chita", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Choibalsan", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Colombo", timeZoneOffset: "+05:30"},
        {timeZoneName: "Asia/Damascus", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Dhaka", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Dili", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Dubai", timeZoneOffset: "+04:00"},
        {timeZoneName: "Asia/Dushanbe", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Famagusta", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Gaza", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Hebron", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Ho_Chi_Minh", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Hong_Kong", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Hovd", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Irkutsk", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Istanbul", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Jakarta", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Jayapura", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Jerusalem", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Kabul", timeZoneOffset: "+04:30"},
        {timeZoneName: "Asia/Kamchatka", timeZoneOffset: "+12:00"},
        {timeZoneName: "Asia/Karachi", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Kathmandu", timeZoneOffset: "+05:45"},
        {timeZoneName: "Asia/Khandyga", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Kolkata", timeZoneOffset: "+05:30"},
        {timeZoneName: "Asia/Krasnoyarsk", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Kuala_Lumpur", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Kuching", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Kuwait", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Macau", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Magadan", timeZoneOffset: "+11:00"},
        {timeZoneName: "Asia/Makassar", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Manila", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Muscat", timeZoneOffset: "+04:00"},
        {timeZoneName: "Asia/Nicosia", timeZoneOffset: "+02:00"},
        {timeZoneName: "Asia/Novokuznetsk", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Novosibirsk", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Omsk", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Oral", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Phnom_Penh", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Pontianak", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Pyongyang", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Qatar", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Qostanay", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Qyzylorda", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Riyadh", timeZoneOffset: "+03:00"},
        {timeZoneName: "Asia/Sakhalin", timeZoneOffset: "+11:00"},
        {timeZoneName: "Asia/Samarkand", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Seoul", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Shanghai", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Singapore", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Srednekolymsk", timeZoneOffset: "+11:00"},
        {timeZoneName: "Asia/Taipei", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Tashkent", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Tbilisi", timeZoneOffset: "+04:00"},
        {timeZoneName: "Asia/Tehran", timeZoneOffset: "+03:30"},
        {timeZoneName: "Asia/Thimphu", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Tokyo", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Tomsk", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Ulaanbaatar", timeZoneOffset: "+08:00"},
        {timeZoneName: "Asia/Urumqi", timeZoneOffset: "+06:00"},
        {timeZoneName: "Asia/Ust-Nera", timeZoneOffset: "+10:00"},
        {timeZoneName: "Asia/Vientiane", timeZoneOffset: "+07:00"},
        {timeZoneName: "Asia/Vladivostok", timeZoneOffset: "+10:00"},
        {timeZoneName: "Asia/Yakutsk", timeZoneOffset: "+09:00"},
        {timeZoneName: "Asia/Yangon", timeZoneOffset: "+06:30"},
        {timeZoneName: "Asia/Yekaterinburg", timeZoneOffset: "+05:00"},
        {timeZoneName: "Asia/Yerevan", timeZoneOffset: "+04:00"},
        {timeZoneName: "Atlantic/Azores", timeZoneOffset: "−01:00"},
        {timeZoneName: "Atlantic/Bermuda", timeZoneOffset: "−04:00"},
        {timeZoneName: "Atlantic/Canary", timeZoneOffset: "+00:00"},
        {timeZoneName: "Atlantic/Cape_Verde", timeZoneOffset: "−01:00"},
        {timeZoneName: "Atlantic/Faroe", timeZoneOffset: "+00:00"},
        {timeZoneName: "Atlantic/Madeira", timeZoneOffset: "+00:00"},
        {timeZoneName: "Atlantic/Reykjavik", timeZoneOffset: "+00:00"},
        {timeZoneName: "Atlantic/South_Georgia", timeZoneOffset: "−02:00"},
        {timeZoneName: "Atlantic/St_Helena", timeZoneOffset: "+00:00"},
        {timeZoneName: "Atlantic/Stanley", timeZoneOffset: "−03:00"},
        {timeZoneName: "Australia/Adelaide", timeZoneOffset: "+09:30"},
        {timeZoneName: "Australia/Brisbane", timeZoneOffset: "+10:00"},
        {timeZoneName: "Australia/Broken_Hill", timeZoneOffset: "+09:30"},
        {timeZoneName: "Australia/Darwin", timeZoneOffset: "+09:30"},
        {timeZoneName: "Australia/Eucla", timeZoneOffset: "+08:45"},
        {timeZoneName: "Australia/Hobart", timeZoneOffset: "+10:00"},
        {timeZoneName: "Australia/Lindeman", timeZoneOffset: "+10:00"},
        {timeZoneName: "Australia/Lord_Howe", timeZoneOffset: "+10:30"},
        {timeZoneName: "Australia/Melbourne", timeZoneOffset: "+10:00"},
        {timeZoneName: "Australia/Perth", timeZoneOffset: "+08:00"},
        {timeZoneName: "Australia/Sydney", timeZoneOffset: "+10:00"},
        {timeZoneName: "Europe/Amsterdam", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Andorra", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Astrakhan", timeZoneOffset: "+04:00"},
        {timeZoneName: "Europe/Athens", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Belgrade", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Berlin", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Bratislava", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Brussels", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Bucharest", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Budapest", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Busingen", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Chisinau", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Copenhagen", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Dublin", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Gibraltar", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Guernsey", timeZoneOffset: "+00:00"},
        {timeZoneName: "Europe/Helsinki", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Isle_of_Man", timeZoneOffset: "+00:00"},
        {timeZoneName: "Europe/Istanbul", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Jersey", timeZoneOffset: "+00:00"},
        {timeZoneName: "Europe/Kaliningrad", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Kiev", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Kirov", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Lisbon", timeZoneOffset: "+00:00"},
        {timeZoneName: "Europe/Ljubljana", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/London", timeZoneOffset: "+00:00"},
        {timeZoneName: "Europe/Luxembourg", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Madrid", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Malta", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Mariehamn", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Minsk", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Monaco", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Moscow", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Nicosia", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Oslo", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Paris", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Podgorica", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Prague", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Riga", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Rome", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Samara", timeZoneOffset: "+04:00"},
        {timeZoneName: "Europe/San_Marino", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Sarajevo", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Saratov", timeZoneOffset: "+04:00"},
        {timeZoneName: "Europe/Simferopol", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Skopje", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Sofia", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Stockholm", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Tallinn", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Tirane", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Ulyanovsk", timeZoneOffset: "+04:00"},
        {timeZoneName: "Europe/Uzhgorod", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Vaduz", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Vatican", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Vienna", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Vilnius", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Volgograd", timeZoneOffset: "+03:00"},
        {timeZoneName: "Europe/Warsaw", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Zagreb", timeZoneOffset: "+01:00"},
        {timeZoneName: "Europe/Zaporozhye", timeZoneOffset: "+02:00"},
        {timeZoneName: "Europe/Zurich", timeZoneOffset: "+01:00"},
        {timeZoneName: "Indian/Antananarivo", timeZoneOffset: "+03:00"},
        {timeZoneName: "Indian/Chagos", timeZoneOffset: "+06:00"},
        {timeZoneName: "Indian/Christmas", timeZoneOffset: "+07:00"},
        {timeZoneName: "Indian/Cocos", timeZoneOffset: "+06:30"},
        {timeZoneName: "Indian/Comoro", timeZoneOffset: "+03:00"},
        {timeZoneName: "Indian/Kerguelen", timeZoneOffset: "+05:00"},
        {timeZoneName: "Indian/Mahe", timeZoneOffset: "+04:00"},
        {timeZoneName: "Indian/Maldives", timeZoneOffset: "+05:00"},
        {timeZoneName: "Indian/Mauritius", timeZoneOffset: "+04:00"},
        {timeZoneName: "Indian/Mayotte", timeZoneOffset: "+03:00"},
        {timeZoneName: "Indian/Reunion", timeZoneOffset: "+04:00"},
        {timeZoneName: "Pacific/Apia", timeZoneOffset: "+13:00"},
        {timeZoneName: "Pacific/Auckland", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Bougainville", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Chatham", timeZoneOffset: "+12:45"},
        {timeZoneName: "Pacific/Chuuk", timeZoneOffset: "+10:00"},
        {timeZoneName: "Pacific/Easter", timeZoneOffset: "−06:00"},
        {timeZoneName: "Pacific/Efate", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Enderbury", timeZoneOffset: "+13:00"},
        {timeZoneName: "Pacific/Fakaofo", timeZoneOffset: "+13:00"},
        {timeZoneName: "Pacific/Fiji", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Funafuti", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Galapagos", timeZoneOffset: "−06:00"},
        {timeZoneName: "Pacific/Gambier", timeZoneOffset: "−09:00"},
        {timeZoneName: "Pacific/Guadalcanal", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Guam", timeZoneOffset: "+10:00"},
        {timeZoneName: "Pacific/Honolulu", timeZoneOffset: "−10:00"},
        {timeZoneName: "Pacific/Kiritimati", timeZoneOffset: "+14:00"},
        {timeZoneName: "Pacific/Kosrae", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Kwajalein", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Majuro", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Marquesas", timeZoneOffset: "−09:30"},
        {timeZoneName: "Pacific/Midway", timeZoneOffset: "−11:00"},
        {timeZoneName: "Pacific/Nauru", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Niue", timeZoneOffset: "−11:00"},
        {timeZoneName: "Pacific/Norfolk", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Noumea", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Pago_Pago", timeZoneOffset: "−11:00"},
        {timeZoneName: "Pacific/Palau", timeZoneOffset: "+09:00"},
        {timeZoneName: "Pacific/Pitcairn", timeZoneOffset: "−08:00"},
        {timeZoneName: "Pacific/Pohnpei", timeZoneOffset: "+11:00"},
        {timeZoneName: "Pacific/Port_Moresby", timeZoneOffset: "+10:00"},
        {timeZoneName: "Pacific/Rarotonga", timeZoneOffset: "−10:00"},
        {timeZoneName: "Pacific/Saipan", timeZoneOffset: "+10:00"},
        {timeZoneName: "Pacific/Tahiti", timeZoneOffset: "−10:00"},
        {timeZoneName: "Pacific/Tarawa", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Tongatapu", timeZoneOffset: "+13:00"},
        {timeZoneName: "Pacific/Wake", timeZoneOffset: "+12:00"},
        {timeZoneName: "Pacific/Wallis", timeZoneOffset: "+12:00"},
        {timeZoneName: "UTC", timeZoneOffset: "+00:00"}
      ],
      synchronisationModeItems: [
        {mode: "Do not synchronize", value: 0},
        {mode: "Atoss Time Control", value: 1},
        {mode: "SQL Stored Procedure", value: 2},
      ],
      showAtcPassword: false,
      licenses: [],
      activationKey: '',
    }
  },
  mounted() {
    this.$store.dispatch("getSettings")
    this.getLicenses()
  },
  methods: {
    ...mapActions([
      "toggleBooleanSetting",
      "saveSetting",
    ]),
    restartService(){
      axios.get(`${API_URL}setting/restart`, {headers: JwtService.getHeader()})
          .then(() => {
          })
          .catch(error => {
            console.log(error)
          })
    },
    saveAtcSettings() {
      let settings = [
        this.atcUrl,
        this.atcUsername,
        this.atcPassword,
        this.atcTimeZone,
        this.atcJobInterval,
        this.atcJobEnabled,
      ]
      settings.forEach(setting => this.saveSetting(setting))
      axios.get(`${API_URL}terminal`, {headers: JwtService.getHeader()})
          .then(() => {
          })
          .catch(error => {
            console.log(error)
          })
    },
    activateLicense() {
      let activationRequest = {
        'activationKey': this.activationKey
      }
      axios.post(`${API_URL}activate/license`, activationRequest, {headers: JwtService.getHeader()})
          .then(() => this.getLicenses())
          .catch(reason => console.log(reason))
    },
    getLicenses() {
      axios.get(`${API_URL}licenses`, {headers: JwtService.getHeader()})
          .then(value => this.licenses = value.data)
          .catch(reason => console.log(reason))
    }
  },
  computed: {
    ...mapGetters([
      "rightsManagement",
      "synchronisationMode",
      "atcUrl",
      "atcUsername",
      "atcPassword",
      "atcJobEnabled",
      "atcJobInterval",
      "atcTimeZone",
    ]),
  }
}
</script>

<style scoped>

</style>
