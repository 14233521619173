import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import BaseView from "@/views/BaseView";
import LoginForm from "@/components/general/LoginForm";
import Dashboard from "@/components/general/Dashboard";
import store from '../store'
import Settings from "@/components/general/Settings";
import Users from "@/components/data/Users";
import Projects from "@/components/data/Projects";
import Workplace from "@/components/data/Workplace";
import CostCenter from "@/components/data/CostCenter";
import Resource from "@/components/data/Resource";
import Terminal from "@/components/general/Terminal";
import Form from "@/components/general/Form";
import Groups from "@/components/data/Groups";
import QrGenerator from "@/components/general/QrGenerator";
import AppLayout from "@/components/general/AppLayout";
import AppForm from "@/components/general/AppForm";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        meta: {
            name: '',
            requiresAuth: false,
        },
        component: LoginView,
        children: [
            {
                path: '',
                name: 'Login',
                component: LoginForm
            }
        ]
    },
    {
        path: '/',
        meta: {
            name: '',
            requiresAuth: true
        },
        component: BaseView,
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard
            },
            {
                path: '/settings',
                name: 'Settings',
                component: Settings
            },
            {
                path: '/users',
                name: 'Users',
                component: Users
            },
            {
                path: '/groups',
                name: 'Groups',
                component: Groups
            },
            {
                path: '/projects',
                name: 'Projects',
                component: Projects
            },
            {
                path: '/workplaces',
                name: 'Workplaces',
                component: Workplace
            },
            {
                path: '/cost_centers',
                name: 'Cost centers',
                component: CostCenter
            },
            {
                path: '/resources',
                name: 'Resources',
                component: Resource
            },
            {
                path: '/terminals',
                name: 'Terminals',
                component: Terminal
            },
            {
                path: '/applayout',
                name: 'App Layout',
                component: AppLayout
            },
            {
                path: '/forms',
                name: 'Forms',
                component: Form
            },{
                path: '/appForms',
                name: 'Forms',
                component: AppForm
            },
            {
                path: '/qr_generator',
                name: 'QR Generator',
                component: QrGenerator
            },
        ]
    },
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let authenticated = store.getters.isAuthenticated || false
        if (authenticated) {
            if (to.path === '/')
                next('/dashboard')
            else
                next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

const DEFAULT_TITLE = 'b.it3 Webservice';
router.afterEach((to) => {
    Vue.nextTick(() => document.title = `${DEFAULT_TITLE} - ${to.name}` || DEFAULT_TITLE)
});

export default router
