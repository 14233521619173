<template>
  <v-container fluid fill-height class="body">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md3>
        <v-card elevation="10">
          <v-toolbar color="blue-grey" dark>
            <v-spacer/>
            <v-toolbar-title class="text-h4">Login</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <v-card-text class="pb-8">
            <v-form
                @submit="login"
                onSubmit="return false;"
            >
              <v-text-field
                  v-model="username"
                  label="Username"
                  prepend-icon="mdi-account"
              />
              <v-text-field
                  v-model="password"
                  label="Password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
              />
              <v-alert type="error" transition="scale-transition" :value="error">
                Wrong Username and/or Password
              </v-alert>
              <v-container class="text-center pb-0">
                <v-btn
                    color="blue-grey darken-2"
                    dark
                    type="submit"
                >Login
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "LoginForm",
  data: function () {
    return {
      username: '',
      password: '',
      showPassword: false,
      error: false,
    }
  },
  methods: {
    login: function () {
      this.$store.dispatch("login", {username: this.username, password: this.password})
          .then(() => {
            if (this.$store.getters.isAuthenticated) {
              this.$router.push('/dashboard')
            }
          })
          .catch((reason) => {
            this.error = (reason.request.status === 401)
          })
    }
  }
}
</script>

<style scoped>

</style>
