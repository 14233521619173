const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getHeader = () => {
    let token = getToken()
    if (token) {
        return {Authorization: 'Bearer ' + token}
    } else {
        return {}
    }
}

export default { getToken, saveToken, destroyToken, getHeader };
