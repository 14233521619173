<template>
  <v-navigation-drawer
      app
      dark
      permanent
  >
    <v-list
        nav
    >

      <v-list-item-group mandatory>
        <v-list-item

            v-for="(item, index) in navigation"
            :key="index"
            @click="navigate(item.href)"
            link
            selectable
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-spacer></v-spacer>

    </v-list>
    <template v-slot:append>
      <v-list nav>
        <v-list-item
            @click="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "Drawer",
  data: function () {
    return {
      navigation: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          href: "/dashboard",
        },
        {
          title: "Settings",
          icon: "mdi-cog",
          href: "/settings",
        },
        {
          title: "Users",
          icon: "mdi-account",
          href: "/users",
        },
        {
          title: "Groups",
          icon: "mdi-account-group",
          href: "/groups",
        },
        {
          title: "Projects",
          icon: "mdi-chart-gantt",
          href: "/projects",
        },
        {
          title: 'Workplaces',
          icon: 'mdi-office-building-marker',
          href: '/workplaces'
        },
        {
          title: 'Cost centers',
          icon: 'mdi-tag',
          href: '/cost_centers'
        },
        {
          title: 'Resources',
          icon: 'mdi-toolbox',
          href: '/resources'
        },
        {
          title: 'Terminals (old)',
          icon: 'mdi-format-list-bulleted-square',
          href: '/terminals'
        },
        {
          title: 'Forms (old)',
          icon: 'mdi-clipboard-list',
          href: '/forms'
        },
        {
          title: 'App Layout',
          icon: 'mdi-format-list-bulleted-square',
          href: '/applayout'
        },
        {
          title: 'App Forms',
          icon: 'mdi-clipboard-list',
          href: '/appForms'
        },
        {
          title: 'QR Generator',
          icon: 'mdi-qrcode',
          href: '/qr_generator'
        },
      ]
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout")
          .then(() => {
            this.$router.push('/login')
          })
          .catch(reason => console.log(reason))
    },
    navigate: function (href) {
      if (this.$router.currentRoute.path !== href)
        this.$router.push(href)
    }
  }
}
</script>

<style scoped>

</style>
