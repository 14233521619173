<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="resourceList"
        :search="search"
        class="elevation-1"
        :items-per-page="20"
        dense
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>
            <v-icon>mdi-toolbox</v-icon>
            Resource
          </v-toolbar-title>
          <v-spacer/>
          <v-text-field
              v-model="search"
              placeholder="Search"
              append-icon="mdi-magnify"
          />
          <v-spacer/>
          <v-btn
              color="primary"
              @click="createItem"
          >
            Create new resource
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            color="warning"
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            color="error"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:item.active="{ item }">
        <v-icon
            :color="item.active ? 'success' : 'error'"
        >
          {{ item.active ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialog"
        fullscreen
        scrollable
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-btn
                icon
                dark
                @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="headline">{{ getFormDialogTitle }} - {{ editedItem.number }}</span>
            <v-spacer/>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="saveItem"
              >
                {{ (editing) ? 'save changes' : 'Create resource' }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.number"
                    :rules="[() => !!editedItem.number || 'This field is required']"
                    :readonly="editing"
                    label="Number"
                    prepend-icon="mdi-key"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.name"
                    :rules="[() => !!editedItem.name || 'This field is required']"
                    label="Name"
                    prepend-icon="mdi-form-textbox"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.company"
                    label="Company"
                    prepend-icon="mdi-domain"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.group"
                    label="Group"
                    prepend-icon="mdi-group"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.costCenter"
                    label="Cost center"
                    prepend-icon="mdi-tag"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.startdate"
                    label="Start date"
                    type="date"
                    prepend-icon="mdi-calendar-start"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.enddate"
                    label="End date"
                    type="date"
                    prepend-icon="mdi-calendar-end"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    v-model="editedItem.latitude"
                    label="Latitude"
                    type="number"
                    prepend-icon="mdi-latitude"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.longitude"
                    label="Longitude"
                    type="number"
                    prepend-icon="mdi-longitude"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="editedItem.radius"
                    label="Radius"
                    type="number"
                    prepend-icon="mdi-map-marker-radius"
                />
              </v-col>
            </v-row>
            <v-row dense>

            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                    v-model="editedItem.active"
                    label="Active"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <h1 class="text-h5">Rights</h1>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    v-model="usersForResource"
                    :items="userList"
                    label="Users"
                    append-outer-icon="mdi-select-multiple"
                    prepend-icon="mdi-account-multiple"
                    @click:append-outer="usersForResource = userList"
                    multiple
                    return-object
                    small-chips
                    deletable-chips
                    :item-text="item => `${item.firstname} ${item.lastname} (${item.number})`"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogDelete"
        fullscreen
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
              icon
              dark
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Delete resource "{{ editedItem.number }} - {{ editedItem.name }}"</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn
                text
                dark
                @click="deletionConfirmed"
            >
              Confirm deletion
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md8>
              <h1 class="text-h2 d-flex justify-center">Should resource "{{ editedItem.number }} - {{ editedItem.name }}" be deleted?</h1>
            </v-flex>
          </v-layout>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";

export default {
  name: "Resources",
  data: function () {
    return {
      search: '',
      dialog: false,
      dialogDelete: false,
      editing: false,
      editedItem: {},
      headers: [
        {text: 'Number', value: 'number'},
        {text: 'Name', value: 'name'},
        {text: 'Cost Center', value: 'costCenter'},
        {text: 'Company', value: 'company'},
        {text: 'Start Date', value: 'startDate', class: 'text-no-wrap'},
        {text: 'End Date', value: 'endDate', class: 'text-no-wrap'},
        {text: 'Latitude', value: 'latitude'},
        {text: 'Longitude', value: 'longitude'},
        {text: 'Radius', value: 'radius'},
        {text: 'Active', value: 'active'},
        {text: 'Group', value: 'grouping'},
        {text: 'Actions', value: 'actions', width: 100},
      ],
      usersForResource: []
    }
  },
  mounted() {
    this.$store.dispatch("getUserList")
    this.$store.dispatch("getResourcesList")
  },
  methods: {
    editItem(item) {
      this.editing = true;
      this.editedItem = Object.assign({}, item)
      this.getUsersForWorkplace(item.number)
      this.dialog = true
    },
    createItem() {
      this.editing = false;
      this.dialog = true
    },
    saveItem() {
      if (this.editing) {
        axios.put(`${API_URL}resource`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getResourcesList")
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        axios.post(`${API_URL}resource`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getResourcesList")
            })
            .catch(error => {
              console.log(error)
            })
      }

      axios.put(`${API_URL}resource/${this.editedItem.number}/users`, this.usersForResource, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deletionConfirmed() {
      axios.delete(`${API_URL}resource/${this.editedItem.number}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getResourcesList")
          })
          .catch(error => {
            console.log(error)
          })
      this.closeDialog()
    },
    closeDialog() {
      if (this.dialog) this.dialog = false;
      if (this.dialogDelete) this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {}
        this.usersForResource = []
      });
    },
    getUsersForWorkplace(projectNumber){
      axios.get(`${API_URL}resource/${projectNumber}/users`, {headers: JwtService.getHeader()})
          .then(response => {
            this.usersForResource = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  computed: {
    ...mapGetters([
      "resourceList",
      "userList",
    ]),
    getFormDialogTitle() {
      return (this.editing) ? "Edit resource" : "Create new resource"
    }
  }
}
</script>

<style scoped>

</style>
