<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn tile class="primary" @click="createTerminal">
          <v-icon>mdi-plus</v-icon>
          Add Terminal
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          v-for="(terminal) in terminals"
          :key="terminal.id"
          cols="6"
          class="col-lg-6 col-sm-12 col-sm-12 col-12"
      >
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon large>mdi-format-list-bulleted-square</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ terminal.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn color="warning" icon @click="editTerminal(terminal)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="copyTerminal(terminal)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn color="error" icon @click="deleteTerminal(terminal)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <v-divider/>

          <template v-for="(action, index) in terminal.actions">
            <v-list :key="action.id">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon :color="action.color" x-large>mdi-{{ action.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h5">{{ action.label }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-btn
                        icon
                        color="secondary"
                        @click="moveAction(terminal, index, index - 1)"
                        :disabled="index === 0"
                    >
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="secondary"
                        @click="moveAction(terminal, index, index + 1)"
                        :disabled="index === (terminal.actions.length - 1)"
                    >
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn icon color="warning" @click="editAction(action, terminal)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="copyAction(terminal, action)">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteObject(terminal, terminal.actions, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider/>
              <v-list class="pt-0">
                <template v-for="(page, index) in action.pages">
                  <v-list-item
                      :key="page.id"
                      dense
                      sub-group
                      no-action
                  >
                    <v-list-item-icon>
                      <v-icon v-if="page.type === 'gps'">mdi-map-marker-radius</v-icon>
                      <v-icon v-if="page.type === 'list'">mdi-format-list-text</v-icon>
                      <v-icon v-if="page.type === 'form'">mdi-clipboard-list</v-icon>
                      <v-icon v-if="page.type === 'qrcode'">mdi-qrcode-scan</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ page.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row>
                        <v-btn
                            color="secondary"
                            icon
                            :disabled="index === 0"
                            @click="movePage(terminal, action, index, index - 1)"
                        >
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            color="secondary"
                            icon
                            :disabled="index === (action.pages.length - 1)"
                            @click="movePage(terminal, action, index, index + 1)"
                        >
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                        <v-btn color="warning" icon @click="editPage(terminal, page)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn color="error" icon @click="deleteObject(terminal, action.pages, index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < action.pages.length - 1" :key="index"/>
                </template>
                <v-divider/>
                <v-list-item>
                  <v-spacer/>
                  <v-list-item-action>
                    <v-btn tile color="primary" @click="createPage(terminal, action)">
                      <v-icon left>mdi-plus</v-icon>
                      Add Page
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider/>
              </v-list>
            </v-list>
            <v-divider v-if="index < terminal.actions.length - 1" :key="index"/>
          </template>
          <v-divider/>
          <v-list-item>
            <v-spacer/>
            <v-list-item-action>
              <v-btn tile color="primary" @click="createAction(terminal)">
                <v-icon left>mdi-plus</v-icon>
                Add Action
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <!--region Add/Edit Terminal Dialog -->
    <v-dialog persistent v-model="dialogTerminal" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">{{ dialogTitle }} Terminal</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.name"
                  label="Terminal name"
                  prepend-icon="mdi-label"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="editedItem.userGroup"
                  label="User group"
                  prepend-icon="mdi-account-multiple"
                  :items="userGroupList"
                  item-text="description"
                  return-object
                  item-value="id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense v-if="editing">
            <v-col>
              <v-file-input
                  v-model="imageFile"
                  label="New drawer image"
                  persistent-hint
                  hint="Image that gets displayed in navigation drawer of app"
              />
              <v-card elevation="0" v-if="editedItem.drawerImage">
                <v-card-title>Current image</v-card-title>
              <v-img
                  :src="'data:image/png;base64,' + editedItem.drawerImage"
                  height="130"
                  contain
              />
              </v-card>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveTerminal">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--endregion-->

    <!--region Add/Edit Terminal Action Dialog -->
    <v-dialog persistent v-model="dialogAction" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">Add Action</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.label"
                  label="Label"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="editedItem.icon"
                  label="Icon"
                  :items="icons"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">mdi-{{ item }}</v-icon>
                  {{ item }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>mdi-{{ item }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="editedItem.color"
                  type="color"
                  label="Color"
              />
            </v-col>
            <v-col>
              <v-text-field
                  v-model="editedItem.fontSize"
                  type="number"
                  label="Font size"
                  placeholder="24"
              />
            </v-col>
            <v-col>
              <v-text-field
                  v-model="editedItem.verticalPadding"
                  type="number"
                  placeholder="5"
                  label="Vertical Padding"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="editedItem.timeAccount"
                  label="Time Account"
                  :items="timeAccounts"
                  item-text="name"
                  item-value="number"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }} ({{ item.number }})
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }} ({{ item.number }})</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  v-model="editedItem.allowOffline"
                  label="Allow offline bookings"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  v-model="editedItem.saveGpsPosition"
                  label="Save GPS position"
                  hint="Saves the gps positions of the bookings. THIS OPTION SHOULD ONLY USED FOR DEBUGING LOCATIONS"
                  persistent-hint
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                  v-model="editedItem.template"
                  label="Last Action Template"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveAction(terminal)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--endregion-->

    <!--region Add/Edit Action Page Dialog -->
    <v-dialog persistent v-model="dialogPage" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">Add Page</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.title"
                  label="Title"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                  v-model="editedItem.type"
                  label="Page Type"
                  item-value="type"
                  :items="pageTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'list'">
            <v-col>
              <v-autocomplete
                  v-model="editedItem.icon"
                  label="Icon"
                  :items="icons"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">mdi-{{ item }}</v-icon>
                  {{ item }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>mdi-{{ item }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="editedItem.type === 'list'">
            <v-col>
              <v-text-field
                  v-model="editedItem.color"
                  type="color"
                  label="Color"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'form'">
            <v-col>
              <v-autocomplete
                  v-model="editedItem.form"
                  label="Form"
                  prepend-icon="mdi-clipboard-list"
                  :items="formList"
                  item-text="name"
                  item-value="id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.timeout"
                  prepend-icon="mdi-progress-clock"
                  type="number"
                  suffix="s"
                  label="Timeout"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'list'">
            <v-col>
              <v-select
                  v-model="editedItem.listType"
                  label="Page Type"
                  item-value="value"
                  :items="fenceTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'list' && editedItem.listType === 'Project'">
            <v-col>
              <v-switch
                  v-model="editedItem.projectState"
                  label="Select project state after project"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'list'">
            <v-col>
              <v-switch
                  v-model="editedItem.gpsFilter"
                  label="Filter List based on GPS position"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'gps'">
            <v-col>
              <v-text-field
                  v-model="editedItem.minAccuracy"
                  type="number"
                  suffix="m"
                  label="Minimum accuracy"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'gps'">
            <v-col>
              <v-switch
                  v-model="editedItem.gpsCheck"
                  label="Check for a valid geofence"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'qrcode'">
            <v-col>
              <v-switch
                  v-model="editedItem.secondaryCamera"
                  label="Flip Camera to secondary camera"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'qrcode'">
            <v-col>
              <v-switch
                  v-model="editedItem.qrSkipFilled"
                  label="Skip already filled fences"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'qrcode'">
            <v-col>
              <v-switch
                  v-model="editedItem.qrValidateSignature"
                  label="Validate Signature of qr code"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type === 'gps' && editedItem.gpsCheck">
            <v-col>
              <v-select
                  v-model="editedItem.checkType"
                  :items="fenceTypes"
                  item-value="value"
                  multiple
                  label="Check type"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="savePage">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--endregion-->

    <!-- region Confirm Deletion Dialog terminal -->
    <v-dialog persistent v-model="dialogDeleteTerminal" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">Confirm deletion of object</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-card-title>Should the terminal "{{ editedItem.name }}" be deleted?</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionOfTerminalConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Confirm Deletion Dialog action/page -->
    <v-dialog persistent v-model="dialogDelete" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">Confirm deletion of object</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-card-title>Should the object "{{ getObjectDesignator }}" be deleted?</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import MDI_ICONS from '@/common/mdi_icons';
import {mapGetters} from "vuex";

export default {
  name: "Terminal",
  data: function () {
    return {
      icons: MDI_ICONS,
      imageFile: '',
      search: '',
      dialogTerminal: false,
      dialogAction: false,
      dialogPage: false,
      dialogDelete: false,
      dialogDeleteTerminal: false,
      editing: false,
      terminalToEdit: {},
      actionToEdit: {},
      editedItem: {},
      toDelete: -1,
      toDeleteFrom: {},
      terminals: [],
      timeAccounts: [],
      pageTypes: [
        {type: 'gps', icon: 'mdi-map-marker-radius', label: 'GPS Page'},
        {type: 'list', icon: 'mdi-format-list-text', label: 'List Page'},
        {type: 'form', icon: 'mdi-clipboard-list', label: 'Form Page'},
        {type: 'qrcode', icon: 'mdi-qrcode-scan', label: 'QR Code Page'}
      ],
      fenceTypes: [
        {value: "Project", icon: "mdi-chart-gantt", label: "Project"},
        {value: "Workplace", icon: "mdi-office-building-marker", label: "Workplace"},
        {value: "CostCenter", icon: "mdi-tag", label: "Cost Center"},
        {value: "User", icon: "mdi-account", label: "User"},
        {value: "Resource", icon: "mdi-toolbox", label: "Resource"},
      ]
    }
  },
  mounted() {
    this.getTerminals()
    this.getTimeAccounts()
    this.$store.dispatch("getUserGroupList")
    this.$store.dispatch("getFormsList")
  },
  methods: {
    //region Dialog functions
    createTerminal() {
      this.editing = false;
      this.dialogTerminal = true
    },
    editTerminal(terminal) {
      this.editedItem = Object.assign({}, terminal)
      this.editing = true
      this.dialogTerminal = true
    },
    copyTerminal(terminal) {
      this.editedItem = Object.assign({}, terminal)
      this.editedItem.name += ` ${Math.floor(Math.random() * 100)}`
      this.editing = false
      this.dialogTerminal = true
    },
    createAction(terminal) {
      this.editing = false
      this.editedItem.id = this.$uuid.v1()
      this.terminalToEdit = terminal
      this.dialogAction = true
    },
    editAction(action, terminal) {
      this.editedItem = action
      this.terminalToEdit = terminal
      this.editing = true
      this.dialogAction = true
    },
    saveTerminal() {
      let editedItem = Object.assign({}, this.editedItem)
      if (this.editing) {
        editedItem.actions = JSON.stringify(editedItem.actions)
        axios.put(`${API_URL}terminal`, editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
        this.uploadImage();
      } else {
        if ('actions' in this.editedItem) {
          editedItem.actions = JSON.stringify(editedItem.actions)
          delete editedItem.id
        } else {
          editedItem.actions = '[]'
        }
        axios.post(`${API_URL}terminal`, editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
      }
      this.closeDialog()
    },
    copyAction(terminal, action) {
      let actionToClone = Object.assign({}, action)
      let terminalToSave = Object.assign({}, terminal)
      actionToClone.id = this.$uuid.v1()

      terminalToSave.actions.push(actionToClone)
      terminalToSave.actions = JSON.stringify(terminalToSave.actions)
      axios.put(`${API_URL}terminal`, terminalToSave, {headers: JwtService.getHeader()})
          .then(() => {
            this.getTerminals()
          })
          .catch(error => {
            console.log(error)
          })
    },
    moveAction(terminal, from, to) {
      let terminalToEdit = Object.assign({}, terminal)
      let move = terminalToEdit.actions.splice(from, 1)[0];
      terminalToEdit.actions.splice(to, 0, move)
      terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

      axios.put(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
          .then(() => {
            this.getTerminals()
          })
          .catch(error => {
            console.log(error)
          })
    },
    saveAction() {
      let terminalToEdit = Object.assign({}, this.terminalToEdit)
      if (this.editing) {
        terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

        axios.put(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        let editedItem = Object.assign({}, this.editedItem)
        terminalToEdit.actions.push(editedItem)

        terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

        axios.post(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
      }
      this.closeDialog()
    },
    createPage(terminal, action) {
      this.editing = false
      this.editedItem.id = this.$uuid.v1()
      this.terminalToEdit = terminal
      this.actionToEdit = action
      this.dialogPage = true
    },
    editPage(terminal, page) {
      this.editedItem = page
      this.terminalToEdit = terminal
      this.editing = true
      this.dialogPage = true
    },
    savePage() {
      let terminalToEdit = Object.assign({}, this.terminalToEdit)
      if (this.editing) {
        terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

        axios.put(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        let action = terminalToEdit.actions.find(value => value.id === this.actionToEdit.id);
        let editedItem = Object.assign({}, this.editedItem)

        if ('pages' in action) {
          action.pages.push(editedItem)
        } else {
          action.pages = []
          action.pages.push(editedItem)
        }

        terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

        axios.post(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getTerminals()
            })
            .catch(error => {
              console.log(error)
            })
      }
      this.closeDialog()
    },
    movePage(terminal, action, from, to) {

      let terminalToEdit = Object.assign({}, terminal)
      let actionToEdit = terminalToEdit.actions.find(value => value.id === action.id)

      let move = actionToEdit.pages.splice(from, 1)[0];
      actionToEdit.pages.splice(to, 0, move)

      terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

      axios.put(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
          .then(() => {
            this.getTerminals()
          })
          .catch(error => {
            console.log(error)
          })
    },
    deleteTerminal(terminal) {
      this.editedItem = terminal
      this.dialogDeleteTerminal = true
    },
    deleteObject(terminal, from, toDelete) {
      this.terminalToEdit = terminal
      this.toDeleteFrom = from
      this.toDelete = toDelete

      this.dialogDelete = true
    },
    deletionConfirmed() {
      this.toDeleteFrom.splice(this.toDelete, 1)
      let terminalToEdit = Object.assign({}, this.terminalToEdit)
      terminalToEdit.actions = JSON.stringify(terminalToEdit.actions)

      axios.put(`${API_URL}terminal`, terminalToEdit, {headers: JwtService.getHeader()})
          .then(() => {
            this.getTerminals()
          })
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    deletionOfTerminalConfirmed() {
      axios.delete(`${API_URL}terminal/${this.editedItem.id}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.getTerminals()
          })
          .catch(error => {
            console.log(error)
          })
      this.closeDialog()
    },
    closeDialog() {
      if (this.dialogPage) this.dialogPage = false;
      if (this.dialogAction) this.dialogAction = false;
      if (this.dialogDelete) this.dialogDelete = false;
      if (this.dialogTerminal) this.dialogTerminal = false;
      if (this.dialogDeleteTerminal) this.dialogDeleteTerminal = false;
      this.$nextTick(() => {
        this.getTerminals()
        this.toDelete = -1
        this.editedItem = {}
        this.toDeleteFrom = {}
        this.actionToEdit = {}
        this.terminalToEdit = {}
      });
    },
    //endregion
    getTerminals() {
      axios.get(`${API_URL}terminals`, {headers: JwtService.getHeader()})
          .then(response => {
            response.data.forEach(value => {
              value.actions = JSON.parse(value.actions)
            })
            this.terminals = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getTimeAccounts() {
      axios.get(`${API_URL}timeaccounts`, {headers: JwtService.getHeader()})
          .then(response => {
            this.timeAccounts = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    uploadImage() {
      if(this.imageFile == null) return;
      let formData = new FormData()
      formData.append('file', this.imageFile)
      axios.post(`${API_URL}terminal/${this.editedItem.id}/image`, formData, {headers: JwtService.getHeader()})
          .then(() => {
            this.imageFile = null;
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  computed: {
    ...mapGetters([
      "userGroupList",
      "formList"
    ]),
    getObjectDesignator() {
      if (this.toDelete !== -1) {
        if ("title" in this.toDeleteFrom[this.toDelete])
          return this.toDeleteFrom[this.toDelete].title
        if ("label" in this.toDeleteFrom[this.toDelete])
          return this.toDeleteFrom[this.toDelete].label
      }
      return ""
    },
    dialogTitle() {
      return (this.editing) ? "Edit" : "Add"
    }
  }
}

</script>

<style scoped>

</style>
