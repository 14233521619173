<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn tile class="primary" @click="createForm">
          <v-icon>mdi-plus</v-icon>
          Add Form
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          v-for="(form) in formList"
          :key="form.id"
          class="col-lg-6 col-sm-12 col-sm-12 col-12"
      >
        <v-list elevation="4">
          <v-list-item>
            <v-list-item-icon><v-icon large>mdi-clipboard-list</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ form.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn color="warning" icon @click="editForm(form)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="copyForm(form)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn color="error" icon @click="deleteForm(form)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <!-- region Form Fields -->
          <v-divider/>
          <v-list-item>
            <v-list-item-action>
              <v-btn tile color="primary" @click="createFormField(form)">
                <v-icon left>mdi-plus</v-icon>
                Add Form Field
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider/>
          <v-list-item-group>
            <template v-for="(field, index) in form.form">
              <v-list-item :key="field.id" dense>
                <v-list-item-icon>
                  <v-icon v-if="field.type === 'number'" large>mdi-numeric</v-icon>
                  <v-icon v-if="field.type === 'text'" large>mdi-form-textbox</v-icon>
                  <v-icon v-if="field.type === 'bool'" large>mdi-ab-testing</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ field.label }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-btn
                        color="secondary"
                        icon
                        v-if="index !== 0"
                        @click="moveField(form, index, index - 1)"
                    >
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        icon
                        v-if="index !== (form.form.length - 1)"
                        @click="moveField(form, index, index + 1)"
                    >
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn color="warning" icon @click="editField(field, form)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" icon @click="deleteObject(form, form.form, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                  v-if="index < form.form.length - 1"
                  :key="index"
              />
            </template>
          </v-list-item-group>
          <!-- endregion -->

        </v-list>
      </v-col>
    </v-row>

    <!--region Add/Edit Form Dialog -->
    <v-dialog persistent v-model="dialogForm" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">{{ dialogTitle }} Form</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.name"
                  label="Form name"
                  prepend-icon="mdi-label"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--endregion-->

    <!--region Add/Edit Form Field Dialog -->
    <v-dialog persistent v-model="dialogField" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">{{ dialogTitle }} Field</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-select
                  v-model="editedItem.type"
                  label="Field Type"
                  item-value="type"
                  :items="fieldTypes"
              >
                <template v-slot:selection="{ item }">
                  <v-icon class="ml-4 mr-4">{{ item.icon }}</v-icon>
                  {{ item.label }}
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.name"
                  prepend-icon="mdi-label"
                  label="Name"
                  :rules="[
                          () => !!editedItem.name || 'Name is required!',
                          v => (v || '').indexOf(' ') < 0 || 'No spaces are allowed',
                          ]"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="editedItem.label"
                  label="Label"
                  prepend-icon="mdi-label"
              />
            </v-col>
            <v-col>
              <v-text-field
                  v-model="editedItem.suffix"
                  label="Suffix"
                  prepend-icon="mdi-label-variant"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch
                  v-model="editedItem.required"
                  label="Required"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="saveFormField">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--endregion-->

    <!-- region Confirm Deletion Dialog field -->
    <v-dialog persistent v-model="dialogDeleteField" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">Confirm deletion of object</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-card-title>Should the form "{{ editedItem.name }}" be deleted?</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionOfFormConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->

    <!-- region Confirm Deletion Dialog form -->
    <v-dialog persistent v-model="dialogDelete" max-width="800">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">Confirm deletion of object</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-card-title>Should the object "{{ getObjectDesignator }}" be deleted?</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- endregion -->
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import MDI_ICONS from '@/common/mdi_icons';
import {mapGetters} from "vuex";

export default {
  name: "Form",
  data: function () {
    return {
      icons: MDI_ICONS,
      dialogForm: false,
      dialogField: false,
      dialogDelete: false,
      dialogDeleteField: false,
      editing: false,
      formToEdit: {},
      editedItem: {},
      toDelete: -1,
      toDeleteFrom: {},
      fieldTypes: [
        {type: "number", icon: "mdi-numeric", label: "Number"},
        {type: "text", icon: "mdi-form-textbox", label: "Text"},
        {type: "bool", icon: "mdi-ab-testing", label: "Bool"},
      ]
    }
  },
  mounted() {
    this.$store.dispatch("getFormsList")
  },
  methods: {
    //region Dialog functions
    createForm() {
      this.editing = false;
      this.dialogForm = true
    },
    editForm(terminal) {
      this.editedItem = Object.assign({}, terminal)
      this.editing = true
      this.dialogForm = true
    },
    copyForm(terminal) {
      this.editedItem = Object.assign({}, terminal)
      this.editedItem.name += ` ${Math.floor(Math.random() * 100)}`
      this.editing = false
      this.dialogForm = true
    },
    saveForm() {
      if (this.editing) {
        let formToSend = Object.assign({}, this.editedItem)
        formToSend.form = JSON.stringify(formToSend.form)
        axios.put(`${API_URL}form`, formToSend, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getFormsList")
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        if ('form' in this.editedItem) {
          let formToSend = Object.assign({}, this.editedItem)
          formToSend.form = JSON.stringify(formToSend.form)
          delete formToSend.id
        } else {
          this.editedItem.form = '[]'
        }
        axios.post(`${API_URL}form`, this.editedItem, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getFormsList")
            })
            .catch(error => {
              console.log(error)
            })
      }
      this.closeDialog()
    },
    createFormField(terminal) {
      this.editing = false
      this.editedItem.id = this.$uuid.v1()
      this.formToEdit = terminal
      this.dialogField = true
    },
    editField(field, form) {
      this.editedItem = field
      this.formToEdit = form
      this.editing = true
      this.dialogField = true
    },
    moveField(form, from, to) {
      let fieldToMove = Object.assign({}, form)
      let move = fieldToMove.form.splice(from, 1)[0];
      fieldToMove.form.splice(to, 0, move)
      fieldToMove.form = JSON.stringify(fieldToMove.form)

      axios.put(`${API_URL}form`, fieldToMove, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getFormsList")
          })
          .catch(error => {
            console.log(error)
          })
    },
    saveFormField() {
      if (this.editing) {
        let formFieldToEdit = Object.assign({}, this.formToEdit)
        formFieldToEdit.form = JSON.stringify(formFieldToEdit.form)

        axios.put(`${API_URL}form`, formFieldToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getFormsList")
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        let formFieldToSave = Object.assign({}, this.editedItem)
        let formFieldToEdit = Object.assign({}, this.formToEdit)

        formFieldToEdit.form.push(formFieldToSave)

        formFieldToEdit.form = JSON.stringify(formFieldToEdit.form)

        axios.post(`${API_URL}form`, formFieldToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.$store.dispatch("getFormsList")
            })
            .catch(error => {
              console.log(error)
            })
      }
      this.closeDialog()
    },
    deleteForm(form) {
      this.editedItem = form
      this.dialogDeleteField = true
    },
    deleteObject(form, from, toDelete) {
      this.formToEdit = form
      this.toDeleteFrom = from
      this.toDelete = toDelete
      this.dialogDelete = true
    },
    deletionConfirmed() {
      this.toDeleteFrom.splice(this.toDelete, 1)

      this.formToEdit.form = JSON.stringify(this.formToEdit.form)

      axios.put(`${API_URL}form`, this.formToEdit, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getFormsList")
          })
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    deletionOfFormConfirmed() {
      axios.delete(`${API_URL}form/${this.editedItem.id}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.$store.dispatch("getFormsList")
          })
          .catch(error => {
            console.log(error)
          })
      this.closeDialog()
    },
    closeDialog() {
      if (this.dialogField) this.dialogField = false;
      if (this.dialogDelete) this.dialogDelete = false;
      if (this.dialogForm) this.dialogForm = false;
      if (this.dialogDeleteField) this.dialogDeleteField = false;
      this.$nextTick(() => {
        this.$store.dispatch("getFormsList")
        this.toDelete = -1
        this.editedItem = {}
        this.toDeleteFrom = {}
        this.actionToEdit = {}
        this.formToEdit = {}
      });
    },
    //endregion
  },
  computed: {
    ...mapGetters([
      "formList"
    ]),
    getObjectDesignator() {
      if (this.toDelete !== -1) {
        if ("name" in this.toDeleteFrom[this.toDelete])
          return this.toDeleteFrom[this.toDelete].name
        if ("label" in this.toDeleteFrom[this.toDelete])
          return this.toDeleteFrom[this.toDelete].label
      }
      return ""
    },
    dialogTitle() {
      return (this.editing) ? "Edit" : "Add"
    }
  }
}

</script>

<style scoped>

</style>
