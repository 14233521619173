<template>
  <v-app id="login-view" style="background-color: transparent">
    <v-main>
      <router-view />
    </v-main>
    <canvas id="bg_canvas"></canvas>
  </v-app>
</template>

<script>
import trianglify from "trianglify";

export default {
  name: "LoginView",
  mounted() {
    const pattern = trianglify({
      width: window.innerWidth.valueOf(),
      height: window.innerHeight.valueOf(),
      seed: "504242",
    })
    window.addEventListener("resize", () => {
      const pattern = trianglify({
        width: window.innerWidth.valueOf(),
        height: window.innerHeight.valueOf(),
        seed: "504242",
      })
      pattern.toCanvas(document.getElementById('bg_canvas'))
    })
    pattern.toCanvas(document.getElementById('bg_canvas'))
  }
}
</script>

<style scoped>
canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0;
}
</style>
