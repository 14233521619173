<template>
  <v-container class="ma-0 pa-0">
    <v-data-table
        :items="projectStates"
        :headers="headers"
        :items-per-page="5"
        dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon>mdi-state-machine</v-icon>
            Project states
          </v-toolbar-title>
          <v-spacer/>
          <v-btn tile small color="primary" @click="add">
            <v-icon>mdi-plus</v-icon>
            Add project state
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            color="warning"
            class="mr-2"
            @click="edit(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            color="error"
            @click="deleteState(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.state="{ item }">
        {{ states.find(value => value.value === item.state).label }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <span class="headline">{{ projectStateActionTitle }} project state</span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-text-field
                  v-model="projectStateToEdit.number"
                  :readonly="editing"
                  label="Project state number"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                  v-model="projectStateToEdit.description"
                  label="Project state description"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                  v-model="projectStateToEdit.state"
                  label="Project state description"
                  :items="states"
                  item-text="label"
                  item-value="value"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-autocomplete
                  v-model="usersForProjectState"
                  :items="userList"
                  label="Users"
                  append-outer-icon="mdi-select-multiple"
                  prepend-icon="mdi-account-multiple"
                  @click:append-outer="usersForProjectState = userList"
                  return-object
                  multiple
                  small-chips
                  deletable-chips
                  :item-text="item => `${item.firstname} ${item.lastname} (${item.number})`"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="900">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
              color="primary"
              dark
          >
            <v-spacer/>
            <span class="headline">Confirm deletion of object</span>
            <v-spacer/>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" dense>
            <v-col>
              <v-card-title>Should the project state "{{ projectStateToEdit.description }}" be deleted?</v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="secondary" @click="closeDialog">
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="deletionConfirmed">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import API_URL from "@/common/config";
import JwtService from "@/service/jwt.service";
import {mapGetters} from "vuex";

export default {
  name: "ProjectState",
  props: {
    projectNumber: String,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editing: false,
      projectStateToEdit: {},
      projectStates: [],
      usersForProjectState: [],
      headers: [
        {text: 'Number', value: 'number'},
        {text: 'Name', value: 'description'},
        {text: 'State', value: 'state'},
        {text: 'Actions', value: 'actions', width: 100},
      ],
      states: [
        {value: 0, label: "Free"},
        {value: 1, label: "Locked"},
        {value: 2, label: "Completed"},
      ]
    }
  },
  mounted() {
    this.getProjectStates()
    this.$store.dispatch("getUserList")
  },
  methods: {
    add() {
      this.editing = false
      this.dialog = true
    },
    edit(item) {
      this.projectStateToEdit = Object.assign({}, item)
      this.getUsersForProjectState(item.number)
      this.editing = true
      this.dialog = true
    },
    deleteState(item) {
      this.projectStateToEdit = item
      this.dialogDelete = true
    },
    save() {
      if (this.editing) {
        axios.put(`${API_URL}project_states/${this.projectNumber}`, this.projectStateToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getProjectStates()
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        this.projectStateToEdit.projectNumber = this.projectNumber
        axios.post(`${API_URL}project_states/${this.projectNumber}`, this.projectStateToEdit, {headers: JwtService.getHeader()})
            .then(() => {
              this.getProjectStates()
            })
            .catch(error => {
              console.log(error)
            })
      }

      axios.put(`${API_URL}project_states/${this.projectNumber}/${this.projectStateToEdit.number}/users`, this.usersForProjectState, {headers: JwtService.getHeader()})
          .catch(error => {
            console.log(error)
          })

      this.closeDialog()
    },
    deletionConfirmed() {
      axios.delete(`${API_URL}project_states/${this.projectNumber}/${this.projectStateToEdit.number}`, {headers: JwtService.getHeader()})
          .then(() => {
            this.getProjectStates()
          })
          .catch(error => {
            console.log(error)
          })
      this.closeDialog()
    },
    closeDialog() {
      if (this.dialog) this.dialog = false;
      if (this.dialogDelete) this.dialogDelete = false;
      this.$nextTick(() => {
        this.projectStateToEdit = {}
        this.usersForProjectState = []
        this.editing = false
      });
    },
    getProjectStates() {
      axios.get(`${API_URL}project_states/${this.projectNumber}`, {headers: JwtService.getHeader()})
          .then(response => {
            this.projectStates = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getUsersForProjectState(stateNumber) {
      axios.get(`${API_URL}project_states/${this.projectNumber}/${stateNumber}/users`, {headers: JwtService.getHeader()})
          .then(response => {
            this.usersForProjectState = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  computed: {
    ...mapGetters([
      "userList",
    ]),
    projectStateActionTitle() {
      return (this.editing) ? 'Edit' : 'Add'
    }
  }
}
</script>

<style scoped>

</style>
