<template>
  <v-app id="base-view">
    <Drawer />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "@/components/core/Drawer";
export default {
  name: "BaseView",
  components: {Drawer}
}
</script>

<style scoped>

</style>
